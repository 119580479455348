import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const StyledProduct = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.dark100};

  p {
    margin-top: 12px;
  }
`

const Product = ({ image, productDescription, alt }) => {
  return (
    <StyledProduct>
      <Img fluid={image} alt={alt} />
      <p>{productDescription}</p>
    </StyledProduct>
  )
}

export default Product
