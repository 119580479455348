import React from "react"
import styled from "styled-components"

import Product from "../product/index"

const StyledProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
  margin: 24px 0 64px;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    grid-template-columns: 1fr;
  }
`

const ProductsGrid = ({ products }) => {
  return (
    <StyledProductGrid>
      {products.map(({ product_image, product_description }, index) => {
        return (
          <Product
            key={index}
            productDescription={product_description}
            image={product_image.localFile.childImageSharp.fluid}
            alt={product_image.alt}
          />
        )
      })}
    </StyledProductGrid>
  )
}

export default ProductsGrid
